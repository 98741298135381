<template>
  <Modal
    :description="description"
    type="destructive"
  >
    <template #title>
      <h1>Ik wil mij aanmelden voor:<br> {{ title }}</h1>
    </template>

    <ul
      class="flex w-full flex-col gap-16 py-6 md:flex-row md:items-center"
    >
      <li>
        <b>Datum</b>
        <p>{{ dateFormat(timeslot.date) }}</p>
      </li>
      <li>
        <b>Locatie</b>
        <p>{{ timeslot.location.title }}</p>
      </li>
      <li>
        <b>Tijd</b>
        <p>{{ timeslot.start_time }} - {{ timeslot.end_time }} uur</p>
      </li>
    </ul>

    <template v-if="!user">
      <div class="flex gap-4">
        <UiLink
          :to="{name: 'login'}"
          variation="primary"
          @click="modal.close()"
        >
          Inloggen
        </UiLink>
        <UiLink
          :to="{name: 'register'}"
          variation="outlineBlack"
          @click="modal.close()"
        >
          Account aanmaken
        </UiLink>
      </div>
      <p class="pt-6">
        <em>*Aanmelden voor een event kan alleen met een account en als je bent ingelogd</em>
      </p>
    </template>

    <template v-else>
      <FormKit
        type="form"
        :actions="false"
        @submit="confirm"
      >
        <label class="text-xl font-medium">Mijn gegevens</label>
        <div class="mb-4 grid md:grid-cols-2 md:gap-4">
          <FormKit
            name="own_name"
            type="text"
            label="Naam"
            outer-class="!mb-0 opacity-75"
            :disabled="true"
            :value="user.name"
          />
          <FormKit
            type="email"
            name="own_email"
            label="E-mailadres"
            outer-class="!mb-0 opacity-75"
            :disabled="true"
            :value="user.email"
          />
        </div>

        <div v-if="timeslot.free_slots > 1">
          <FormKit
            v-model="guestCount"
            type="select"
            label="Ik neem mee*"
            validation="required"
            :options="guestCountOptions"
          />
          <div>
            <div
              v-for="index in guestCount"
              :key="index"
              class="!mt-2"
            >
              <label class="text-xl font-medium">Gast {{ index }}</label>
              <FormKit
                v-model="guests[index - 1]"
                :name="`guest-${index}`"
                type="group"
              >
                <div class="grid md:grid-cols-2 md:gap-4">
                  <FormKit
                    name="name"
                    type="text"
                    label="Naam*"
                    outer-class="!mb-0"
                    validation="required"
                  />
                  <FormKit
                    type="email"
                    name="email"
                    label="E-mailadres*"
                    outer-class="!mb-0"
                    validation="required|email"
                  />
                </div>
                <hr class="my-4">
              </FormKit>
            </div>
          </div>
          <FormKit
            v-model="accountManager"
            type="text"
            name="account_manager"
            label="Accountmanager"
            placeholder="Door wie ben je uitgenodigd? (optioneel)"
          />
        </div>
        <button
          v-if="timeslot.free_slots >= 1"
          type="submit"
          class="text w-fit rounded-full bg-primary px-3 py-4 text-base leading-none transition hover:bg-secondary"
        >
          Direct aanmelden
        </button>
        <p v-else>
          Er zijn geen plekken meer beschikbaar bij dit evenement
        </p>
      </FormKit>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import type {Timeslot} from '~/graphql/graphql';
const user = useSofieUserWhenAuthenticated();

const props = defineProps<{
  title: string
  description: string
  timeslot: Timeslot
}>();

const accountManager = ref('');

const guestCount = ref(0);
const guests = ref([]);
const guestCountOptions = computed(() => {
  return [
    {value: 0, label: 'Geen gasten'},
    {value: 1, label: '1 gast'},
    {value: 2, label: '2 gasten'},
    {value: 3, label: '3 gasten'},
    {value: 4, label: '4 gasten'},
  ].filter(opt => opt.value <= props.timeslot.free_slots - 1);
});

const emit = defineEmits<{
  cancel: [],
  confirm: [{
    guests: typeof guests.value,
    account_manager: typeof accountManager.value,
  }],
}>();

const modal = useModalInstance();

function confirm(): void {
  emit('confirm', {
    guests: guests.value.splice(0, guestCount.value),
    account_manager: accountManager.value,
  });
  modal.close();
}
</script>
